<template>
    <div>
        <bread-crumb :navigationArray="navigationLink"/>
        <div class="white-container" :class="{'content-list': getListView}">
            <div class="white-container__title">
                <h5>Законопроекты для обсуждения</h5>
                <div class="button_block">
                    <button type="button" @click="$store.dispatch('setView', false)" class="btn__icon btnBlock" :class="{'active' : getListView}"></button>
                    <button type="button" @click="$store.dispatch('setView', true)" class="btn__icon btnList" :class="{'active' : !getListView}"></button>
                </div>
            </div>
            <tricolor-line class="tricolor_line"/>
            <component :is="`bills-${getListView ? 'list' : 'block'}`" :data="bills" />
            <pagination-block class="news__pagination" :current="currentPage" :total="getTotalPage" @page-changed="getPage" />
        </div>
    </div>
</template>
<script>
import BreadCrumb from '@/components/BreadCrumb'
import TricolorLine from '@/components/TricolorLine'
import BillsBlock from '@/components/activitiesSurveys/BillsBlock'
import BillsList from '@/components/activitiesSurveys/BillsList'
import Pagination from '@/components/Pagination'

export default {
  name: 'Bills',
  components: {
    'bread-crumb': BreadCrumb,
    'tricolor-line': TricolorLine,
    'bills-block': BillsBlock,
    'bills-list': BillsList,
    'pagination-block': Pagination
  },
  data () {
    return {
      currentPage: 0,
      countPage: 50,
      navigationLink: [
        {
          title: 'Главная',
          path: '/'
        },
        {
          title: 'Деятельность',
          path: '/'
        },
        {
          title: 'Законопроекты для обсуждения'
        }
      ],
      bills: [
        {
          date: '01.01.2020',
          title: 'Законопроект о противодействии коррупции',
          subtitle: 'Новый законопроект относится к противодействию коррупции на территории РФ и Новый законопроект относится',
          photo: 'hammer.svg',
          link: '/adopted_bill'
        },
        {
          date: '01.01.2020',
          title: 'Законопроект о противодействии коррупции',
          subtitle: 'Новый законопроект относится к противодействию коррупции на территории РФ и Новый законопроект относится',
          photo: 'hammer.svg',
          link: '/adopted_bill'
        },
        {
          date: '01.01.2020',
          title: 'Законопроект о противодействии коррупции',
          subtitle: 'Новый законопроект относится к противодействию коррупции на территории РФ и Новый законопроект относится',
          photo: 'hammer.svg',
          link: '/adopted_bill'
        },
        {
          date: '01.01.2020',
          title: 'Законопроект о противодействии коррупции',
          subtitle: 'Новый законопроект относится к противодействию коррупции на территории РФ и Новый законопроект относится',
          photo: 'hammer.svg',
          link: '/adopted_bill'
        },
        {
          date: '01.01.2020',
          title: 'Законопроект о противодействии коррупции',
          subtitle: 'Новый законопроект относится к противодействию коррупции на территории РФ и Новый законопроект относится',
          photo: 'hammer.svg',
          link: '/adopted_bill'
        },
        {
          date: '01.01.2020',
          title: 'Законопроект о противодействии коррупции',
          subtitle: 'Новый законопроект относится к противодействию коррупции на территории РФ и Новый законопроект относится',
          photo: 'hammer.svg',
          link: '/adopted_bill'
        },
        {
          date: '01.01.2020',
          title: 'Законопроект о противодействии коррупции',
          subtitle: 'Новый законопроект относится к противодействию коррупции на территории РФ и Новый законопроект относится',
          photo: 'hammer.svg',
          link: '/adopted_bill'
        },
        {
          date: '01.01.2020',
          title: 'Законопроект о противодействии коррупции',
          subtitle: 'Новый законопроект относится к противодействию коррупции на территории РФ и Новый законопроект относится',
          photo: 'hammer.svg',
          link: '/adopted_bill'
        }
      ]
    }
  },
  computed: {
    getTotalPage () {
      return this.$store.getters.getContent.total ? this.$store.getters.getContent.total : 50
    },
    getListView () {
      return this.$store.getters.getListView
    }
  },
  methods: {
    getPage (page) {
      console.log(page)
      this.currentPage = page
    }
  }
}
</script>
<style lang="scss" scoped>
.white-container{
    width: auto;
    margin: 0 5rem;
    padding: 2.5rem 2.94rem 0.625rem 2.95rem;
    &__title{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1.81rem;
    }
}
.content-list{
    width: 61.44rem;
    margin: 0 5rem;
}
h5{
    font-weight: 600;
    font-size: 1.38rem;
    line-height: 1.75rem;
    color: #1F3245;
}
.tricolor_line{
    margin-bottom: 2rem;
}
.news__pagination{
    margin-top: 0.625rem;
    padding: 2.5rem 0 1.87rem 0;
    border-top: .0625rem solid #D5D6D9;
}
.button_block {
    display: flex;
}
@media screen and (max-width: 768px){
  .white-container{
    width: auto;
    margin: 0 .75rem;
    padding: 1.86rem 1.25rem;
  }
}
@media screen and (max-width: 420px){
    .white-container{
        width: 100%;
        margin: 0;
        padding: 1.88rem 1rem;
    }
}
</style>
