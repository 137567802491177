<template>
    <div class="contain">
        <div class="info-banner" v-for="(list, key) in data" :key="`link${key}`">
            <span class="info-banner__type" :style="`background: url('${'/assets/icons/' + list.photo}') center / cover`"></span>
            <div class="info-banner__block">
                <p class="info-banner__date">{{ dateArr(list.date) }}</p>
                <p class="info-banner__title" v-trim="1">{{ list.title }}</p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  name: 'BillsList',
  props: {
    data: {
    //   type: Object
    }
  },
  methods: {
    dateArr (date) {
      return (new Date(date).toLocaleString('ru', { day: 'numeric', month: 'long', year: 'numeric' }).slice(0, -3))
    }
  }
}
</script>
<style lang="scss" scoped>
.contain{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 0.625rem;
}
.info-banner {
  margin-bottom: 1.25rem;
  padding: 1.25rem 1.875rem;
  width: 100%;
  height: 5.88rem;

  box-shadow: 0px .94rem 1.56rem rgba(189, 205, 221, 0.35);
  border-radius: .5rem;
  border: .06rem solid #E5E8EE;

  display: flex;
  align-items: flex-start;
  font-size: 0.875rem;

  &__type {
    width: 2.8125rem;
    height: 2.8125rem;
    margin-bottom: auto;
  }
  &__block{
    display: flex;
    flex-direction: column;
    margin-left: 1.25rem;
    width: calc(100% - 1.25rem - 2.8125rem);
  }
  &__date{
    font-size: .875rem;
    line-height: 1.37rem;
    color: #5E686C;
    opacity: 0.8;
    margin-bottom: .625rem;
  }

  &__title{
    font-weight: 600;
    font-size: .875rem;
    line-height: 1.38rem;
    color: #1F3245;
  }
}
@media screen and (max-width: 768px){
}
@media screen and (max-width: 420px){
}
</style>
